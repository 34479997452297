import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import HeaderNew from 'components/HeaderNew';

import EmailForm from './components/EmailForm';
import { tablet, useQuery } from 'styles/breakpoints';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import { Quiz } from 'types/quiz';
import BackGroundQuizImage from '../../../assets/images/lastingChange/quiz/BG-des3.png';
import theme from 'utils/theme';
import BlackFridayBanner from 'components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import Cookies from 'js-cookie';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';

const HeaderContainer = styled.div`
  position: relative;
  max-width: 500px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.lightBg};
  @media ${tablet} {
    margin: unset;
    padding-top: unset;
  }
`;

const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightBg};
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 3rem 1rem;
  gap: 1.5rem;
  height: calc(100vh - 68px);
  @media ${tablet} {
    padding: 1.5rem 1rem;
    gap: 0.75rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  flex-direction: column;

  @media ${tablet} {
    max-width: 100%;
  }
`;

const Title = styled(Text)`
  width: 460px;
  line-height: 130%;
  margin-bottom: 1rem;
  @media ${tablet} {
    max-width: 343px;
    width: 100%;
  }
`;

const Subtitle = styled(Text)`
  padding-bottom: 2rem;
  text-align: center;
  width: 440px;
  @media ${tablet} {
    max-width: 343px;
    width: 100%;
    text-align: center;
  }
`;

const Footer = styled(Text)`
  text-align: center;
  width: 420px;
  color: rgba(77, 77, 77, 0.64);
  margin-bottom: 3rem;
  margin: auto auto 4rem auto;

  @media ${tablet} {
    margin-bottom: 1.5rem;
    max-width: 343px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${tablet} {
    max-width: 7.5rem;
    width: 100%;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)``;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();
  const { quiz_answers, user, geolocation, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const { isTablet } = useQuery();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
  });
  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
          }),
        )) ?? '';

      dispatch(updateQuizAnswers(trackingParams));
      // cliendCodeToUrl(code);

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        userState,
        user_ip,
      );

      Cookies.set('rdId', code, {
        expires: 7,
        secure: true,
        sameSite: 'strict',
      }); // expires in 7 days

      Cookies.set('rdEm', email, {
        expires: 7,
        secure: true,
        sameSite: 'strict',
      });

      Events.checkoutPageVisit(code);

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo title="Dog Training Book | Raising Dog" />
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <PageContainer>
        <Header logoVariant="center" sticky={false} color="lightBg" />
        <Container>
          <ContentContainer>
            <Title
              type={isTablet ? 'h3800' : 'h1S600'}
              textAlign="center"
              color="dark100"
            >
              {data?.title}
              {quiz_answers?.userDogName}
              {data?.title1}
            </Title>
            <Subtitle type={isTablet ? 'bodyM' : 'bodyM'} color="dark80">
              {data?.inputLabel}
            </Subtitle>
            <EmailFormStyled
              loading={loading}
              error={error}
              onSubmit={handleGetResults}
              continueBtnTitle={data?.continueBtnTitle}
              placeholder={data?.inputPlaceholder}
            />
            {data.img ? (
              <ImageContainer>
                <DynamicImage
                  src={data.img}
                  alt={data.alt}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>
            ) : null}
          </ContentContainer>
          <Footer type={isTablet ? 'bodyS' : 'bodyS'} color="dark60">
            {data?.footer}
          </Footer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Email;
